<template>
  <div class="EduHome">
    <!-- <LoginHeader /> -->
    <EduHeader :memberAvatar="avatar" />
    <main class="MyMessage-container">
      <div class="MyMessageMian">
        <div class="MyMessageTitle">我的账户</div>
        <div class="MyMessageFont" v-if="memberData">
          我的账户编号：{{ memberData.id }}
        </div>

        <div class="MyMessageCenter">
          <personal-info :memberData="memberData" @set-avatar="setAvatar" />
        </div>
      </div>
    </main>
    <!-- <footer class="LoginFooter">
      <div class="FooterCenter">
        <ul>
          <li>首页华路</li>
          <li>•</li>
          <li>国际名校</li>
          <li>•</li>
          <li>资讯中心</li>
          <li>•</li>
          <li>华路校友会</li>
          <li>•</li>
          <li>华路商学院简介</li>
          <li>•</li>
          <li>我要约课</li>
        </ul>
        <div class="FooterRight">
          <p>COPYRIGHT © 2009-2020 华路数字文化 版权所有</p>
          <p>www.cufehl.com  粤ICP备14094585号-1</p>
        </div>
      </div>
    </footer> -->
    <login-footer />
  </div>
</template>

<script>
import EduHeader from "@/components/EduHeader";
import LoginFooter from "@/components/LoginFooter";
//import LoginHeader from "@/components/LoginHeader";
import PersonalInfo from "./components/PersonalInfo/index.vue";
import {
  getMemberProfile,
  // getColdcallById,
} from "@/api/memberController";
export default {
  name: "MyMessage",
  data() {
    return {
      memberData: null,
      avatar: "",
    };
  },
  components: {
    //LoginHeader,
    PersonalInfo,
    LoginFooter,
    EduHeader,
  },
  created() {
    this.getMemberProfile();
  },
  methods: {
    setAvatar(avatar) {
      this.avatar = avatar;
      sessionStorage.setItem("MEMBER_AVATAR", avatar);
    },
    getMemberProfile() {
      getMemberProfile().then((res) => {
        if (res.code == 20000) {
          this.memberData = res.data;
          this.avatar = res.data.avatar;
        } else {
          this.$baseMessage(res.data.msg, "error", "vab-hey-message-error");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.MyMessage-container {
  flex: 1;
  padding: 35px 40px 42px;
  margin-top: 60px;
  .MyMessageMian {
    width: 1200px;
    margin: 0 auto;
    color: #333333;
    .MyMessageTitle {
      font-size: 35px;
    }
    .MyMessageFont {
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 0px;
      color: #666666;

      line-height: 36px;
      margin-top: 10px;
    }
    .MyMessageToggle {
      height: 50px;
      margin-top: 15px;
      div {
        cursor: pointer;
        float: left;
        width: calc(25% - 1px);
        margin-left: 1px;
        height: 50px;
        background: #ffffff;
        border: 1px solid #cccccc;

        border-radius: 6px;
        box-sizing: border-box;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 0px;
        color: #666666;
        line-height: 48px;
        text-align: center;
        &.on {
          height: 50px;
          background: #303030;
          border: 1px solid #cccccc;

          border-radius: 6px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 0px;
          color: #ffffff;
          line-height: 48px;
        }
      }
    }
    .MyMessageCenter {
      // height: 441px;
      //background: #ffffff;
      border: 1px solid #a5a5a5;

      border-radius: 6px;
      margin-top: 15px;
      // padding: 10px 15px;
      line-height: 24px;
      // overflow: auto;
      background: #f2f3f3;
      // ul {
      //   li {
      //     padding: 5px 0;
      //   }
      // }
    }
  }
}

.EduHome {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  footer {
    padding: 24px 40px 40px;
    font-size: 16px;
    color: $color-text-6;
    background-color: $color-background-5;
  }
}
.LoginFooter {
  border-top: 1px solid #bfbfbf;
  .FooterCenter {
    width: 1200px;
    margin: 0 auto;
    padding: 20px 0;
    ul {
      float: left;
      li {
        float: left;
        margin-right: 20px;
      }
    }
    .FooterRight {
      float: right;
      text-align: right;
      p {
        padding: 0;
        margin: 0;
        line-height: 24px;
      }
    }
  }
}
</style>
