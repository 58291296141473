<template>
  <div class="PersonalInfo">
    <main class="personal-page-container">
      <div class="PersonalInfoMian">
        <div class="PersonalInfoFrom">
          <div class="PersonalInfoFromTitle">完善个人信息</div>
          <div class="PersonalInfoFromTable" v-if="PersonalInfoFrom">
            <table width="100%">
              <tr>
                <td>
                  <div class="FontTitle">姓名</div>
                  <div class="FontInput">
                    <input
                      v-model="PersonalInfoFrom.fullname"
                      type="text"
                      placeholder="请填写姓名"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="FontTitle">手机号码</div>
                  <div class="FontInput">
                    <input
                      v-model="PersonalInfoFrom.username"
                      type="text"
                      placeholder="请填写手机号码"
                      :disabled="true"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="FontTitle">上传自己头像</div>
                  <div class="clr">
                    <div class="headPortrait">
                      <img
                        v-if="PersonalInfoFrom.avatar"
                        :src="PersonalInfoFrom.avatar"
                      />
                    </div>
                    <div class="uploadRight">
                      <div class="uploadBut">
                        上传本地照片
                        <input
                          type="file"
                          id="file"
                          name="upload_image"
                          multiple="multiple"
                          accept="image/gif, image/jpeg, image/png, image/jpg"
                          @change="showImg"
                        />
                      </div>
                      <div class="uploadFont">
                        仅支持jpg、 jpeg、gif、png 格式的图片文件文件不能大于2MB
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="FontTitle">邮箱</div>
                  <div class="FontInput">
                    <input
                      v-model="PersonalInfoFrom.email"
                      type="text"
                      placeholder="请填写邮箱"
                    />
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <button
            :disabled="isUpload"
            @click="improveExtraInfo"
            type="button"
            class="PersonalInfoButton"
          >
            提交
          </button>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
// import BindWeChat from "./components/BindWeChat.vue";
// import BindWeChatTip from "./components/BindWeChatTip.vue"
//import md5 from "js-md5";
import {
  //improveExtraInfo,
  uploadImages,
  improveBaseInfo,
  // getColdcallById,
} from "@/api/memberController";
import { isEmail } from "@/utils/validate";
export default {
  name: "PersonalInfo",
  data() {
    return {
      codeUrl: "",
      PersonalInfoFrom: {},
      RegisterFrom: {},
      show: true,
      count: "",
      timer: null,
      checkedInfo: true,
      isUpload: false,
    };
  },
  watch: {
    memberData: {
      // eslint-disable-next-line no-unused-vars
      handler(val, oldval) {
        this.PersonalInfoFrom = val;
      },
      deep: true,
    },
  },
  props: {
    memberData: {
      type: Object,
      default: null,
    },
  },
  components: {
    // BindWeChat,
    // BindWeChatTip,
  },
  created() {
    this.PersonalInfoFrom = this.memberData;
  },
  methods: {
    showImg(event) {
      let files = event.target.files;
      if (files && files.length > 0) {
        // 获取目前上传的文件
        let file = files[0];
        // 来在控制台看看到底这个对象是什么
        console.log(file);
        // 那么我们可以做一下诸如文件大小校验的动作
        if (file.size > 1024 * 1024 * 2) {
          this.$baseMessage(
            "图片大小不能超过 2MB!",
            "warning",
            "vab-hey-message-warning"
          );
          return false;
        }
        // !!!!!!
        // 下面是关键的关键，通过这个 file 对象生成一个可用的图像 URL
        // 获取 window 的 URL 工具
        let URL = window.URL || window.webkitURL;
        // 通过 file 生成目标 url
        let imgURL = URL.createObjectURL(file);
        this.PersonalInfoFrom.avatar = imgURL;
        const formData = new FormData();
        formData.set("file", file);

        uploadImages(formData).then((res) => {
          this.isUpload = false;
          if (res.code == 20000) {
            this.$baseMessage(
              "上传成功,上传之后记得提交哦!",
              "success",
              "vab-hey-message-success"
            );
            this.PersonalInfoFrom.avatar = res.data.fullUrl;
          } else {
            this.$baseMessage(res.data.msg, "error", "vab-hey-message-error");
          }
        });
      }
    },
    getCode() {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    async improveExtraInfo() {
      //this.$refs["bindWeChat"].showEdit();
      if (this.isUpload) {
        this.$baseMessage(
          "正在上传头像，请上传图像之后再提交!",
          "warning",
          "vab-hey-message-warning"
        );
        return;
      }
      let memberInfo = {};
      memberInfo.username = this.PersonalInfoFrom.username;
      memberInfo.fullname = this.PersonalInfoFrom.fullname;
      if (this.PersonalInfoFrom.email) {
        if (!isEmail(this.PersonalInfoFrom.email)) {
          this.$baseMessage(
            "请输入正确得邮箱!",
            "warning",
            "vab-hey-message-warning"
          );
          return;
        }
      }
      memberInfo.email = this.PersonalInfoFrom.email;
      if (this.PersonalInfoFrom.avatar) {
        memberInfo.avatar = this.PersonalInfoFrom.avatar;
      }
      improveBaseInfo(memberInfo).then((res) => {
        if (res.code == 20000) {
          if (memberInfo.avatar) {
            this.$emit("set-avatar", memberInfo.avatar);
          }
          this.$baseMessage("修改成功", "success", "vab-hey-message-success");
        } else {
          this.$baseMessage(res.data.msg, "error", "vab-hey-message-error");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.personal-page-container {
  flex: 1;
  .PersonalInfoMian {
    width: 100%;
    margin: 0 auto;
    color: #333333;
    .PersonalInfoTitle {
      font-size: 35px;
    }
    .PersonalInfoItem {
      background: #f2f3f3;
      border: 1px solid #d8d8d8;

      border-radius: 6px;
      line-height: 24px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;

      i {
        float: left;
        width: 24px;
        height: 24px;
        display: block;
        background: url("../../../../assets/register/item-icon.png") no-repeat
          center;
        background-size: cover;
        margin-right: 10px;
      }
    }
    .PersonalInfoFrom {
      background: #f2f3f3;
      border: 1px solid #d8d8d8;

      border-radius: 6px;
      padding: 30px 100px 50px 100px;
      .PersonalInfoFromTitle {
        font-size: 30px;
        line-height: 60px;
        color: #575B67;
      }
      .PersonalInfoFromTable {
        margin: 0 -15px;
        border: 0;
        padding-bottom: 20px;
        td {
          padding: 0 15px;
          box-sizing: border-box;
          width: 50%;
          .FontTitle {
            line-height: 40px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
          }
          .FontInput {
            height: 45px;
            background: #ffffff;
            border: 1px solid #989898;

            border-radius: 3px;
            box-sizing: content-box;
            font-size: 0;
            input {
              width: 100%;
              box-sizing: border-box;
              border: 0;
              padding: 0 15px;
              height: 45px;
              font-size: 16px;
              border: 0;
              background: transparent;
            }
          }
        }
      }
    }
    .PersonalInfoButton {
      background: #173d7c;
      border-radius: 10px;
      cursor: pointer;
      border: 0;
      font-size: 16px;
      color: #ffffff;
      margin-top: 10px;
      width: 149px;
      height: 50px;

      &:hover {
        background: #244f96;
      }
    }
  }
}
.FontButton {
  padding: 0 15px;
  height: 45px;
  font-size: 16px;
  border: 1px solid #989898;
  color: #989898;
}
.code {
  cursor: pointer;
  height: 45px;
  margin-top: 3px;
}

.login-protocol {
  font-size: 16px;
  color: #666666;
  line-height: 24px;
  span {
    color: #173d7c;
  }
}

.PersonalInfo {
  display: flex;
  flex-direction: column;
  //min-height: 100vh;
}
.LoginFooter {
  border-top: 1px solid #bfbfbf;
  .FooterCenter {
    width: 1200px;
    margin: 0 auto;
    padding: 20px 0;
    ul {
      float: left;
      li {
        float: left;
        margin-right: 20px;
      }
    }
    .FooterRight {
      float: right;
      text-align: right;
      p {
        padding: 0;
        margin: 0;
        line-height: 24px;
      }
    }
  }
}
.clr{
  .headPortrait{
    float: left;
    width: 122px;
    height: 122px;
    background: #EEEEEE url("~@/assets/MyMessage/w88Img.png") no-repeat center;
    background-size: auto 88px;
    border: 1px solid #BBB2B2;
    border-radius: 4px;
    img {
      width: 122px;
      height: 122px;
    }
  }
  &::after{
    clear: both;
    display: block;
    content: "";
  }
  .uploadRight{
    padding-left: 145px;
  }
  .uploadBut{
    width: 140px;
    height: 45px;
    background: #8B8B8B;
    border-radius: 3px 3px 3px 3px;
    opacity: 1;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    text-align: center;
    line-height: 45px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    input{
      width: 100%;
      height: 100%;
      cursor: pointer;
      overflow: hidden;
      outline: 0;
      opacity:0;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  .uploadFont{
    font-size: 16px;
    font-weight: 400;
    color: #666666;
    margin-top: 20px;
  }
}
</style>
